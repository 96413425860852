function pma_back_to_top_link() {
    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > 50) {
            jQuery('#back-to-top').fadeIn();
        } else {
            jQuery('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    jQuery('#back-to-top').click(function () {
        jQuery('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });
}

function normalizeSlideHeights() {
    jQuery('.carousel').each(function(){
      var items = jQuery('.carousel-item', this);
      // reset the height
      items.css('min-height', 0);
      // set the height
      var maxHeight = Math.max.apply(null,
          items.map(function(){
              return jQuery(this).outerHeight()}).get() );
      items.css('min-height', maxHeight + 'px');
    });
}

function pma_handle_top_bar_toggle() {
    let top_bar_toggle = jQuery("#blue-strip-toggle");
    let top_bar_nav = jQuery("#top-bar-nav");

    top_bar_toggle.on("click", function() {
        top_bar_nav.toggleClass('expanded');
    });
}

function pma_handle_home_promo_bar() {
    let promo_banner = jQuery("#promo-banner");
    let close_promo_banner = jQuery("#close-promo-banner");

    close_promo_banner.on("click", function() {
        promo_banner.css('display', 'none');
        Cookies.set('or_promo_banner_closed', 'true', { expires: 30 });
    });
}

jQuery( function ( $ ) {
    'use strict';

    jQuery(window).on( 'load resize orientationsize', normalizeSlideHeights() );

    pma_back_to_top_link();
    pma_show_hide_bottom_bar();
    pma_handle_top_bar_toggle();
    pma_handle_home_promo_bar();

    // The WordPress Default Widgets
    // Now we'll add some classes for the WordPress default widgets - let's go

    // the search widget
    $( '.widget_search input.search-field' ).addClass( 'form-control' );
    $( '.widget_search input.search-submit' ).addClass( 'btn btn-default' );
    $( '.variations_form .variations .value > select' ).addClass( 'form-control' );
    $( '.widget_rss ul' ).addClass( 'media-list' );

    $( '.widget_meta ul, .widget_recent_entries ul, .widget_archive ul, .widget_categories ul, .widget_nav_menu ul, .widget_pages ul, .widget_product_categories ul' ).addClass( 'nav flex-column' );
    $( '.widget_meta ul li, .widget_recent_entries ul li, .widget_archive ul li, .widget_categories ul li, .widget_nav_menu ul li, .widget_pages ul li, .widget_product_categories ul li' ).addClass( 'nav-item' );
    $( '.widget_meta ul li a, .widget_recent_entries ul li a, .widget_archive ul li a, .widget_categories ul li a, .widget_nav_menu ul li a, .widget_pages ul li a, .widget_product_categories ul li a' ).addClass( 'nav-link' );

    $( '.widget_recent_comments ul#recentcomments' ).css( 'list-style', 'none').css( 'padding-left', '0' );
    $( '.widget_recent_comments ul#recentcomments li' ).css( 'padding', '5px 15px');

    $( 'table#wp-calendar' ).addClass( 'table table-striped');

    // Adding Class to Woocommerce form
    $('.woocommerce-Input--text, .woocommerce-Input--email, .woocommerce-Input--password').addClass('form-control');
    $('.woocommerce-Button.button').addClass('btn btn-primary mt-2').removeClass('button');

    $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        $(this).parent().siblings().removeClass('open');
        $(this).parent().toggleClass('open');
    });

    // Add Option to add Fullwidth Section
    function fullWidthSection(){
        var screenWidth = $(window).width();
        if ($('.entry-content').length) {
            var leftoffset = $('.entry-content').offset().left;
        }else{
            var leftoffset = 0;
        }
        $('.full-bleed-section').css({
            'position': 'relative',
            'left': '-'+leftoffset+'px',
            'box-sizing': 'border-box',
            'width': screenWidth,
        });
    }
    fullWidthSection();
    $( window ).resize(function() {
        fullWidthSection();
    });

    // Allow smooth scroll
    $('.page-scroller').on('click', function (e) {
        e.preventDefault();
        var target = this.hash;
        var $target = $(target);
        $('html, body').animate({
            'scrollTop': $target.offset().top
        }, 1000, 'swing');
    });

});

function pma_show_hide_bottom_bar() {
    let bottom_bar = jQuery( '#bottom-bar' );
    let bottom_bar_title = jQuery( '#bottom-bar .bottom-title' );
    let bottom_bar_message = jQuery( '#bottom-bar .message-wrapper' );

    bottom_bar_message.hide();

    bottom_bar_title.on( 'click', function( event ) {
        bottom_bar.toggleClass( 'expanded' );
        bottom_bar_message.slideToggle();
    } );
}